import React, { useState } from 'react';
import './App.css';
import { SocialIcon } from 'react-social-icons';


function Landing() {

  return (
    <div className="Page" id="home">

        <h1>
          Code That Down LLC
        </h1>

        <section>
          Software, Mobile and Robotics Consulting.
        </section>

        <section style={{fontSize: '15pt'}}>
          We are a small consulting firm located in the State of Georgia.
        </section>

        <section className="social-media-icons" style={{fontSize: '13pt'}}>
          <SocialIcon target={"_blank"} url="mailto:contact@codethatdown.com" title="email"/>
          <SocialIcon target={"_blank"} url="https://www.linkedin.com/company/code-that-down" title="email"/>
        </section>
        
    </div>
  );

}


function App() {
  return (
    <div>
      {Landing()}
    </div>
  );
}

export default App;
